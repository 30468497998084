import Lottie from "lottie-react";
import React from "react";

export default function DisplayLottie({animationData}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData
  };

  return <Lottie {...defaultOptions} />;
}
